<template>
  <header>
  </header>

  <main>
    <router-view />
  </main>

  <footer>
  </footer>
</template>

<style lang="scss">
  @import "assets/scss/main";

  html, body {
    padding: 0;
    margin: 0;
    width: 100%;
    min-height: 100vh;
  }

  #app {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 0 0 0;

    & > main {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
    }
  }
</style>
