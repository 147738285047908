<template>
  <div class="main-banner">
    <div class="d-flex flex-column flex-md-row">
      <div class="flex-fill d-flex align-items-center justify-content-center">
        <div class="text-center px-3">
          <h1>Full Stack Web &lt;Developer/&gt;</h1>
          <p class="m-0 text-muted">I am a backend developer who knows how to center a &lt;div/&gt;</p>
        </div>
      </div>
      <div class="flex-fill d-flex align-items-center justify-content-center">
        <img src="../assets/img/neimark/neimark-500.png" alt="Neimark" />
      </div>
    </div>
  </div>

  <div v-for="(item, index) of items" class="view-section" :key="index">
    {{ item }}
  </div>
</template>

<script>

export default {
  name: 'Home',
  data: () => ({
    items: []
  }),
  beforeMount () {
    document.body.classList.add('body-home');
  },
  beforeUnmount () {
    document.body.classList.remove('body-home');
  }
};
</script>

<style lang="scss">
  .body-home {
    background-color: #111111;
    color: white;

    .main-banner {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
      width: 100%;
      position: relative;

      img {
        width: 90%;
        max-width: 90vw;
        max-height: 90vh;
        position: relative;
        z-index: 1;
      }

      h1 {
        white-space: nowrap;
      }
    }

    .view-section {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
      width: 100%;

      img {
        max-width: 90vw;
        max-height: 90vh;
      }
    }
  }
</style>
